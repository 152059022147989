export const navigation = [
  {
    text: 'Stripe',
    icon: 'user',
    path: '',
    items: [
      {
        text: 'Transactions',
        path: '/stripe-transactions',
      },
      {
        text: 'Charges',
        path: '/stripe-list',
      },
    ],
  }
];
