import React from 'react';
import './stripe-list.scss';
import DataGrid, {
  Column,
  ColumnChooser, FilterBuilderPopup, FilterPanel, FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Scrolling,
  SearchPanel,
  Sorting, Summary, TotalItem, GroupItem, MasterDetail, DataGridTypes
} from 'devextreme-react/data-grid';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

const DetailTemplate = (props: DataGridTypes.MasterDetailTemplateData) => {
  const data = props.data.data;
  const { refunds } = data;
  console.log(refunds);

  const dataSource = new DataSource({
    store: new ArrayStore({
      data: refunds,
      key: 'id',
    }),
  });

  return (
    <React.Fragment>
      <div className='master-detail-caption'>
        Refunds
      </div>
      <DataGrid
        dataSource={dataSource}
        showBorders
        columnAutoWidth
      >
        <Column dataField='id' />
        <Column dataField='amount' format={value => value.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
        <Column dataField='created' dataType='date' format={value => moment(value).format('DD/MM/YYYY hh:mm:ss')} />
        <Column dataField='payment_transaction_id' />
        <Summary>
          <TotalItem
            column='amount'
            summaryType='sum'
            valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} />
        </Summary>
      </DataGrid>
    </React.Fragment>
  );
};
export const StripeList = () => {
  const onCellPrepared = (e) => {
    if (e.column.type === 'detailExpand' && e.rowType === 'data' && e.data.amount_refunded == 0) {
      e.cellElement.style.visibility = 'hidden';
    }
  };

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list'>
        <DataGrid
          dataSource='data/charges.json'
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          showBorders
          onCellPrepared={onCellPrepared}
        >
          <GroupPanel visible />
          <SearchPanel visible />
          <Grouping autoExpandAll />
          <Scrolling mode='virtual' />

          <SearchPanel visible />
          <ColumnChooser enabled />
          <Sorting mode='multiple' />

          <FilterRow
            visible
            applyFilter='auto' />
          <FilterPanel visible />
          <FilterBuilderPopup />
          <HeaderFilter visible />

          <Column dataField='id' />
          <Column dataField='amount' format={value => value.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
          <Column dataField='amount_captured' visible={false} format={value => value.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
          <Column dataField='amount_refunded' visible={false} format={value => value.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
          <Column dataField='created' defaultSortOrder='desc' dataType='date' format={value => moment(value).format('DD/MM/YYYY hh:mm:ss')} />
          <Column dataField='description' />
          <Column dataField='payment_intent' visible={false} />
          <Column dataField='customer_id' visible={false} />
          <Column dataField='customer' />
          <Column dataField='payment_transaction_id' visible={false} />
          <Summary>
            <GroupItem
              column='id'
              summaryType='count'
              showInGroupFooter
              alignByColumn
            />
            <GroupItem
              column='amount'
              summaryType='sum'
              valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              showInGroupFooter
              alignByColumn
            />
            <GroupItem
              column='amount_captured'
              summaryType='sum'
              valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              showInGroupFooter
              alignByColumn
            />
            <GroupItem
              column='amount_refunded'
              summaryType='sum'
              valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              showInGroupFooter
              alignByColumn
            />
            <TotalItem
              column='id'
              summaryType='count' />
            <TotalItem
              column='amount'
              summaryType='sum'
              valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} />
            <TotalItem
              column='amount_captured'
              summaryType='sum'
              valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} />
            <TotalItem
              column='amount_refunded'
              summaryType='sum'
              valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} />
          </Summary>
          <MasterDetail enabled component={DetailTemplate} />
        </DataGrid>
      </div>
    </div>
  );
};
