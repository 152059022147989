import React from 'react';

import classNames from 'classnames';

import From, { Item as ItemForm, GroupItem, ColCountByScreen } from 'devextreme-react/form';

import { FormTextbox } from '../../utils/form-textbox/FormTextbox';

import { Contact } from '../../../types/crm-contact';

interface FormGroup {
  caption: string;
  colCount: number;
  colCountByScreen: number;
  items: FormGroupItem[];
}

interface FormGroupItem {
  label: string;
  text: any;
  colSpan: number;
}

function CustomItemForm(label: string, text: any, colSpan = 1) {
  return <ItemForm colSpan={colSpan}>
    <FormTextbox
      label={label}
      value={text}
      isEditing={false}
      onValueChange={ () => { console.log(''); } }
    />
  </ItemForm>;
}

function formGroupRender(group: FormGroup) {
  return <GroupItem colCount={group.colCount} caption={group.caption}>
    <ColCountByScreen xs={group.colCountByScreen} />
    {group.items.map((item) => {
      return CustomItemForm(item.label, item.text, item.colSpan);
    })}
  </GroupItem>;
}

export const OwnerFormDetails = ({ data, editing }: {
  data: Contact, editing: boolean }) => {
  return (
    <From
      className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
      labelMode='floating'
    >
      <GroupItem colCount={2}>
        <ColCountByScreen xs={2} />
        {CustomItemForm('Status', data.status)}
        {CustomItemForm('Position', data.position)}
        {CustomItemForm('Assigned to', data.manager)}
        {CustomItemForm('Company2', data.company)}
      </GroupItem>

      { formGroupRender({ caption: 'Contacts', colCount: 4, colCountByScreen: 2, items: [
        { label: 'Address', text: data.address, colSpan: 4 },
        { label: 'City', text: data.city, colSpan: 2 },
        { label: 'State', text: data.state.stateShort },
        { label: 'Zip Code', text: data.zipCode },
        { label: 'Phone', text: data.phone, colSpan: 2 },
        { label: 'Email', text: data.email, colSpan: 2 }
      ] } as FormGroup) }
    </From>
  );
};
