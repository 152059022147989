import React from 'react';
import DataGrid, {
  Column, ColumnChooser, FilterBuilderPopup, FilterPanel, FilterRow,
  Grouping, GroupItem,
  GroupPanel, HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel, Sorting, Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import './stripe-transactions.scss';
import moment from 'moment';
export const StripeTransactions = () => {
  return (
    <>
      <DataGrid
        keyExpr='id'
        dataSource='data/transactions.json'
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        showBorders
        height='100%'
      >
        <GroupPanel visible />
        <SearchPanel visible />
        <Grouping autoExpandAll />
        <Scrolling columnRenderingMode='virtual' />
        <Paging enabled={false} />

        <SearchPanel visible />
        <ColumnChooser enabled />
        <Sorting mode='multiple' />

        <FilterRow
          visible
          applyFilter='auto' />
        <FilterPanel visible />
        <FilterBuilderPopup />
        <HeaderFilter visible />

        <Column dataField='type' />
        <Column dataField='amount' format={value => value.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
        <Column dataField='description' />
        <Column dataField='payment_transaction_id' defaultSortOrder='asc' />
        <Column dataField='created' dataType='date' format={value => moment(value).format('DD/MM/YYYY hh:mm:ss')} />
        <Column dataField='payout_id' />
        <Column dataField='payout_available_on' dataType='date' format={value => moment(value).format('DD/MM/YYYY hh:mm:ss')} />
        <Column dataField='refund' />
        <Column dataField='charge' groupIndex={0} />
        <Column dataField='customer' />

        <Column dataField='net' visible={false} format={value => value.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
        <Column dataField='fee' visible={false} format={value => value.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
        <Column dataField='payment_intent' visible={false} />
        <Column dataField='available_on' visible={false} dataType='date' format={value => moment(value).format('DD/MM/YYYY hh:mm:ss')} />
        <Column dataField='payout_created' visible={false} dataType='date' format={value => moment(value).format('DD/MM/YYYY hh:mm:ss')} />
        <Column dataField='customer_id' visible={false} />
        <Column dataField='id' visible={false} />
        <Summary>
          <GroupItem
            column='id'
            summaryType='count'
            showInGroupFooter
            alignByColumn
          />
          <GroupItem
            column='amount'
            summaryType='sum'
            valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            showInGroupFooter
            alignByColumn
          />
          <GroupItem
            column='net'
            summaryType='sum'
            valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            showInGroupFooter
            alignByColumn
          />
          <GroupItem
            column='fee'
            summaryType='sum'
            valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            showInGroupFooter
            alignByColumn
          />
          <TotalItem
            column='id'
            summaryType='count' />
          <TotalItem
            column='amount'
            summaryType='sum'
            valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} />
          <TotalItem
            column='net'
            summaryType='sum'
            valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} />
          <TotalItem
            column='fee'
            summaryType='sum'
            valueFormat={value => parseFloat(value).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} />
        </Summary>
      </DataGrid>
    </>
  );
};
