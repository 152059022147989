import React from 'react';
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';

import {
  CardNotes,
  CardMessages,
  CardTasks,
} from '../..';

export const OwnerCards = ({
  isLoading,
  tasks,
  name,
  notes,
  messagesCount,
  messages,
  onMessagesCountChanged
}) => {
  return (
    <div className='dx-card details-card'>
      <TabPanel showNavButtons deferRendering={false}>
        <TabPanelItem title='Alloggi'>
          <CardTasks
            isLoading={isLoading}
            tasks={tasks}
          />
        </TabPanelItem>
        <TabPanelItem title='Archivi fatture'>
          <CardTasks
            isLoading={isLoading}
            tasks={tasks}
          />
        </TabPanelItem>
        <TabPanelItem title='Note'>
          <CardNotes items={notes} user={name} />
        </TabPanelItem>
        <TabPanelItem title='Note sugli addebiti'>
          <CardNotes items={notes} user={name} />
        </TabPanelItem>
        <TabPanelItem title='Commenti' badge={messagesCount}>
          <CardMessages items={messages} user={name} onMessagesCountChanged={onMessagesCountChanged} />
        </TabPanelItem>
      </TabPanel>
    </div>
  );
};
