import React from 'react';

import Toolbar, { Item } from 'devextreme-react/toolbar';

import './OwnerToolbarForm.scss';

export const OwnerToolbarForm = () => {
  return (
    <Toolbar className='toolbar-form'>
      <Item location='before'>
        <span className='dx-form-group-caption'>Details</span>
      </Item>
    </Toolbar>
  );
};
