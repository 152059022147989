import React, { useState, useEffect } from 'react';

import { OwnerToolbarForm } from '../../utils/owner-toolbar-form/OwnerToolbarForm';
import { OwnerFormDetails } from './OwnerFormDetails';

import { withLoadPanel } from '../../../utils/withLoadPanel';

import { Contact } from '../../../types/crm-contact';

import ValidationGroup from 'devextreme-react/validation-group';

import './OwnerForm.scss';

const OwnerFormDetailsWithLoadPanel = withLoadPanel(OwnerFormDetails);

export const OwnerForm = ({ data, isLoading = false }: { data?: Contact, isLoading: boolean }) => {
  const [editing] = useState(false);
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <div className='contact-form'>
      <ValidationGroup>
        <OwnerToolbarForm />
        <OwnerFormDetailsWithLoadPanel
          loading={isLoading}
          hasData={!!formData}
          data={formData}
          editing={editing}
          panelProps={{
            container: '.contact-form',
            position: { of: '.contact-form' },
          }}
        />
      </ValidationGroup>
    </div>
  );
};
